











































































import { InputSetups } from '@/mixins/input-setups'
import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import ChatCryptoCommandHandlerModuleBuilder
  from '@/includes/logic/Modules/models/modules/ChatCryptoCommandHandlerModule/ChatCryptoCommandHandlerModuleBuilder'
import SelectOptionsMixin from '@/mixins/SelectOptionsMixin'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'

import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    SelectInput,
  },
})
export default class ChatCryptoCommandHandlerModuleView extends Mixins(InputSetups, TariffsTagsHelper, ModuleBindings, SelectOptionsMixin) {
  @VModel() module!: ChatCryptoCommandHandlerModuleBuilder

  get tariffTag() {
    return this.module.tariffTags()
  }

  mounted() {
    if (this.tariffTag) {
      this.$baseTemplate.saveButton.hide()
    }
  }
}
